export const Footer = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id='footer' className='app-footer mx-0 px-0 mb-100px'>
        &copy; 2022 Metropolitan Chess Center - Dindin All Rights Reserved
      </div>
    </div>
  )
}
